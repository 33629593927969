let FirebaseConfig = {}

if (process.env.REACT_APP_API_STAGE === 'Dev') {
  FirebaseConfig = {
    apiKey: "AIzaSyB871UMAxkauQP66vgzytUEh16avUXTwuw",
    authDomain: "oumuamua-news.firebaseapp.com",
    projectId: "oumuamua-news",
    storageBucket: "oumuamua-news.appspot.com",
    messagingSenderId: "992530620430",
    appId: "1:992530620430:web:3972c5f0f8067340139c5f",
    measurementId: "G-8PC7785HD1"
  };
} else
  if (process.env.REACT_APP_API_STAGE === 'userDev') {
    FirebaseConfig = {
      apiKey: "AIzaSyDsv5k1nAEyZCHwNDhxBqQwXMcEkzessF0",
      authDomain: "oumuamua-user-test.firebaseapp.com",
      projectId: "oumuamua-user-test",
      storageBucket: "oumuamua-user-test.firebasestorage.app",
      messagingSenderId: "268028757028",
      appId: "1:268028757028:web:ba30f9e5823c911b0404b1"
    };
  } else {
    FirebaseConfig = {
      apiKey: "AIzaSyDjDcuvOwGMMvDgWtmXxSwmG3fSV17jK8Y",
      authDomain: "oumuamua-news-live.firebaseapp.com",
      projectId: "oumuamua-news-live",
      storageBucket: "oumuamua-news-live.appspot.com",
      messagingSenderId: "1030475324965",
      appId: "1:1030475324965:web:981715f383d117b73929cc",
      measurementId: "G-0K4DSF0XYK"
    };
  }

export default FirebaseConfig
