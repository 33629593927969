const SITE_NAME = process.env.REACT_APP_SITE_NAME
let dev = {
  API_ENDPOINT_URL:
    ''
}
let prod = {
  API_ENDPOINT_URL:
    ''
}
let test = {
  API_ENDPOINT_URL:
    ''
}


if (process.env.REACT_APP_API_STAGE === 'Dev') {
  //api domain for test 
  dev = {
    API_ENDPOINT_URL:
      'https://newsapi.24liveyard.com/api/v1'
  }

  prod = {
    API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1'
  }

  test = {
    API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1'
  }
} else
  if (process.env.REACT_APP_API_STAGE === 'userDev') {
    //api domain for user-test 
    dev = {
      API_ENDPOINT_URL:
        'https://apiuat.24liveyard.com/api/v1'
    }

    prod = {
      API_ENDPOINT_URL: 'https://apiuat.24liveyard.com/api/v1'
    }

    test = {
      API_ENDPOINT_URL: 'https://apiuat.24liveyard.com/api/v1'
    }
  } else {
    //api domain for live
    dev = {
      API_ENDPOINT_URL:
        'https://api.oumuamua.news/api/v1'
    }

    prod = {
      API_ENDPOINT_URL: 'https://api.oumuamua.news/api/v1'
    }

    test = {
      API_ENDPOINT_URL: 'https://api.oumuamua.news/api/v1'
    }

  }


const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      // return dev
      return process.env.REACT_APP_API_STAGE === 'Dev' ? dev : prod
    case 'production':
      return process.env.REACT_APP_API_STAGE === 'Dev' ? dev : prod
    case 'test':
      return test
    default:
      break
  }
}

export const env = getEnv()
